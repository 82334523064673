import {Button, Drawer, Dropdown, Menu, Navbar, useTheme} from "react-daisyui";
import {Menu as MenuIcon, } from "lucide-react";
import React, { useEffect, useState } from "react";
import ThemeWindow, {themes} from "./SettingsMenu";
import {UserMenu} from "../pages/UserMenu";
import {ReportClick} from "../utils/gtag";
import SettingsMenu from "./SettingsMenu";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import {Email, Phone} from "@mui/icons-material";
import TMLogo from "../assets/img/gertc_minimalistic_logo_TM.png";

import LanguageSwitcher from "../utils/languageswitcher";
import {useLanguage} from "../utils/languagecontext";

export function TopBar()  {
    const {session, setSession, authPanelOpen, setAuthPanelOpen, openPage, activeTheme, setActiveTheme, setShowSidebar, showSideBar} = useAppContext();
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const { setTheme } = useTheme();
    const [showSettings, setShowSettings] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [selectedMenu, setSelectedMenu] = useState("");

    const { setCurrentLanguage } = useLanguage();
    const { translations } = useLanguage();
    console.log("Rendered Translations in Component:", translations);

    const menuitems = (translations?.topBar
            ? [
                { id: "diensten", display: translations.topBar.diensten, sidebar: false },
                /*{ id: "nieuws", display: translations.topBar.nieuws, sidebar: false },*/
                { id: "info", display: translations.topBar.info, sidebar: false },
                { id: "contact", display: translations.topBar.contact, sidebar: false },
            ]
            : []
    ).map(item => ({
        ...item,
        display: item.display
            ? item.display.toUpperCase()
            : "TRANSLATION IS MISSING.",
    }));

    useEffect(() => {
        let mc = document.querySelector("#maincontainer")
        if(mc!== null) mc.addEventListener("scroll", (e) => {
            setScrollPos(e.target.scrollTop);
        });
        return(() => {
            mc.removeEventListener("scroll", (e) => {
                setScrollPos(e.target.scrollTop);
            });
        })
    })

    return (
        <>
            <div
                id="navbar-wrapper"
                className={"fixed w-full h-16 flex-row top-0 z-10 border-b bg-base-100 lg:bg-opacity-95 lg:backdrop-blur-sm " + (scrollPos === 0 ? "border-transparent" : "border-base-content/10")}
            >
                <div className="container">

                    <Navbar className="flex">

                        <Navbar.Start className="flex gap-2">

                            {/*DRAWER*/}
                            <div className="flex lg:hidden lg:-ml-0">
                                <Drawer className={"z-50"}
                                        open={drawerOpened}
                                        onClickOverlay={() => setDrawerOpened(!drawerOpened)}
                                        side={
                                            <Menu className="min-h-full w-56 lg:gap-2 bg-base-100 p-2">

                                                {/* TECH MANIA Menu Item */}
                                                <Menu.Item
                                                    className={`font-medium ${openPage() === "landing" ? "text-primary underline" : "text-secondary"}`}
                                                    style={{
                                                        color: openPage() === "landing" ? "var(--tw-text-primary)" : undefined,
                                                    }}
                                                >
                                                    <Link
                                                        to="/"
                                                        className={`hover:text-primary focus:text-primary active:text-primary ${openPage() === "landing" ? "text-primary" : ""}`}
                                                        onClick={() => {
                                                            setShowSidebar(false);
                                                        }}
                                                    >
                                                        TECH MANIA
                                                    </Link>
                                                </Menu.Item>

                                                {/* HOME Menu Item */}
                                                <Menu.Item
                                                    className={`font-medium ${openPage() === "landing" ? "text-primary underline" : "text-secondary"}`}
                                                    style={{
                                                        color: openPage() === "landing" ? "var(--tw-text-primary)" : undefined,
                                                    }}
                                                >
                                                    <Link
                                                        to="/"
                                                        className={`hover:text-primary focus:text-primary active:text-primary ${openPage() === "landing" ? "text-primary" : ""}`}
                                                        onClick={() => {
                                                            setShowSidebar(false);
                                                        }}
                                                    >
                                                        HOME
                                                    </Link>
                                                </Menu.Item>

                                                {/* Dynamic Menu Items from menuitems */}
                                                {menuitems.map((el) => (
                                                    <Menu.Item
                                                        key={el.id}
                                                        className={`font-medium ${openPage() === el.id ? "text-primary underline" : "text-secondary"}`}
                                                        style={{
                                                            color: openPage() === el.id ? "var(--tw-text-primary)" : undefined,
                                                        }}
                                                    >
                                                        <Link
                                                            to={"/" + el.id}
                                                            className={`hover:!text-primary focus:!text-primary active:!text-primary ${
                                                                openPage() === el.id ? "text-primary" : ""
                                                            }`}
                                                            onClick={() => setShowSidebar(el.sidebar)}
                                                        >
                                                            {el.display}
                                                        </Link>
                                                    </Menu.Item>
                                                ))}

                                                {/*<SettingsMenu setActiveTheme={setActiveTheme}/>*/}

                                            </Menu>
                                        }
                                >
                                    <Link to="/">
                                        <Button
                                            shape="square"
                                            color="ghost"
                                            onClick={() => {
                                                ReportClick("Login Drawer");
                                                setDrawerOpened(true);
                                            }}
                                        >
                                            <MenuIcon className="inline-block text-xl"/>
                                        </Button>
                                    </Link>
                                </Drawer>
                            </div>
                            {/*DRAWER END*/}

                            <Link to={"/"} className={"hidden lg:flex h-[40px] w-full"}>
                                <div className={"flex items-center bg-primary rounded-md bg-opacity-50"}>
                                    {/*<img
                                        alt="TMLogo"
                                        id="tech-mania-logo"
                                        className="w-[50px] p-3"
                                        src={TMLogo}
                                    />*/}
                                    <div
                                        className={
                                            "flex text-center items-center justify-center h-[40px] p-2 opacity-50" +
                                            (openPage() === "landing" ? " text-secondary " : " text-secondary ")
                                        }
                                        style={{
                                            fontFamily: "'Jaro', serif",
                                            fontStyle: "normal",
                                            textDecoration: "underline",
                                            textUnderlineOffset: 2,
                                            fontSize: "clamp(1.5rem, 2.5vw, 1.5rem)",
                                            "@media (min-width: 1000px) and (max-width: 1200px)": {
                                            fontSize: "clamp(0.5rem, 2.5vw, 0.9rem)"}, // Scales font size responsively
                                            maxWidth: "100%", // Ensures text stays within container
                                            overflow: "hidden", // Prevents overflow beyond container
                                            textOverflow: "ellipsis", // Truncates text if it overflows
                                            whiteSpace: "nowrap", // Keeps text on a single line
                                            lineHeight: "1", // Maintains consistent height
                                        }}
                                        onClick={() => {
                                            setShowSidebar(false);
                                        }}
                                    >
                                        TECH MANIA
                                    </div>
                                </div>
                            </Link>

                        </Navbar.Start>

                        <Navbar.Center className="">
                            <Menu horizontal size="sm" className={"hidden lg:flex"}>

                                <Menu.Item
                                    /*className={`font-medium ${openPage() === "landing" ? "text-primary underline" : "text-secondary"}`}
                                    style={{
                                        color: openPage() === "landing" ? "var(--tw-text-primary)" : undefined,
                                    }}*/
                                >
                                <Dropdown className="dropdown-bottom">
                                        <Dropdown.Toggle
                                            size="sm"
                                            color="ghost"
                                            button={false}
                                            className={`font-semibold ${openPage() === "landing" ? "text-primary underline" : ""}`}
                                            style={{
                                            }}
                                            onClick={() => setShowSidebar(false)}
                                        >
                                            <Link
                                                to="/"
                                                className={`hover:text-primary focus:text-primary active:text-primary uppercase ${openPage() === "landing" ? "text-primary underline" : "text-secondary"}`}
                                            >
                                                {translations?.topBar?.home || "Translation is missing."}
                                            </Link>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item as="div">
                                                <Link to="/een-technologische-toekomst" className="text-secondary hover:text-primary">EEN TECHNOLOGISCHE TOEKOMST</Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item as="div">
                                                <Link to="/react-de-kracht-van-moderne-webontwikkeling" className="text-secondary hover:text-primary">DE KRACHT VAN MODERNE WEBONTWIKKELING</Link>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Item>

                                {menuitems.map((el) => (
                                    <Menu.Item
                                        className={`font-medium ${
                                            openPage() === el.id ? "text-primary underline" : "text-secondary"
                                        }`}
                                        style={{
                                            color: openPage() === el.id ? "var(--tw-text-primary)" : undefined,
                                        }}
                                        key={el.id}
                                    >
                                        <Link
                                            to={`/${el.id}`}
                                            className={`hover:!text-primary focus:!text-primary active:!text-primary ${
                                                openPage() === el.id ? "text-primary" : ""
                                            }`}
                                            onClick={() => {
                                                setShowSidebar(el.sidebar);
                                            }}
                                        >
                                            {el.display}
                                        </Link>
                                    </Menu.Item>
                                ))}

                            </Menu>

                            <Link to={"/contact"} className={"flex lg:hidden"}>
                                <div className={"flex text-nowrap text-secondary text-l"}
                                     style={{width: "100%"}}
                                     color="ghost"
                                     onClick={() => {
                                         setShowSidebar(false);
                                     }}> {/*TECH MANIA*/}
                                    <Phone/> +(0) 32 493 30 83 52
                                </div>
                            </Link>

                        </Navbar.Center>

                        <Navbar.End className="gap-2">

                            {/*---TEMP DISABLED---*/}
                            {/*<div className="hidden z-10 md:hidden lg:flex flex-col items-center mt-1">
                                <SettingsMenu />
                            </div>
                            <div className={"flex flex-row justify-end lg:mr-8 xl:mr-8"}>
                                <UserMenu />
                            </div>*/}

                            <Link to={"/contact"} className={"hidden lg:flex"}>
                                <div className={"flex text-nowrap text-secondary text-l"}
                                     style={{width: "100%"}}
                                     color="ghost"
                                     onClick={() => {
                                         setShowSidebar(false);
                                     }}> {/*TECH MANIA*/}
                                    <Phone/> +(0) 32 493 30 83 52
                                </div>
                            </Link>

                            <div className="flex">
                                <LanguageSwitcher setLanguage={setCurrentLanguage}/>
                            </div>

                        </Navbar.End>

                    </Navbar>
                </div>
            </div>
        </>
    );
}