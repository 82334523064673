import React, { useState, useEffect, useRef } from "react";

const WebsiteCarousel = () => {
    const websites = [
        { url: "https://aetherhouse.xyz/", scale: 0.25 },
        { url: "https://play.manacaster.com/", scale: 0.25 },
        { url: "https://tech-mania.be/website-op-maat", scale: 0.25 },
        { url: "https://tech-mania.be/webdesignbureau-aalst", scale: 0.25 },
        { url: "https://tech-mania.be/mobiele-app-laten-maken", scale: 0.25 },
    ];

    const [startIndex, setStartIndex] = useState(0);
    const [visibleCount, setVisibleCount] = useState(4);
    const [aspectRatio, setAspectRatio] = useState("16 / 9");

    const iframeRefs = useRef([]); // Refs to manage iframes

    const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex + 1) % websites.length);
    };

    const handlePrev = () => {
        setStartIndex((prevIndex) => (prevIndex - 1 + websites.length) % websites.length);
    };

    const updateDimensions = () => {
        const width = window.innerWidth;

        if (width <= 768) {
            setVisibleCount(1);
            setAspectRatio("7 / 4");
        } else if (width <= 1280) {
            setVisibleCount(2);
            setAspectRatio("12 / 7");
        } else if (width <= 2048) {
            setVisibleCount(3);
            setAspectRatio("16 / 10");
        } else {
            setVisibleCount(4);
            setAspectRatio("16 / 10");
        }
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    // UseEffect to handle the interval, but the iframes should not reload on every interval
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    // Generate the visible websites based on current startIndex and visibleCount
    const visibleWebsites = [];
    for (let i = 0; i < visibleCount; i++) {
        visibleWebsites.push(websites[(startIndex + i) % websites.length]);
    }

    return (
        <div className="relative flex items-center justify-center w-full bg-gray-100">
            <button
                onClick={handlePrev}
                className="absolute left-2 bg-primary text-white rounded-full w-10 h-10 flex items-center justify-center z-10"
            >
                &#8592;
            </button>

            <div className="flex w-full h-full justify-between items-center overflow-hidden">
                {visibleWebsites.map((website, index) => (
                    <div
                        key={index}
                        style={{
                            flexBasis: `${100 / visibleCount}%`,
                            position: "relative",
                            overflow: "hidden",
                        }}
                        className="relative w-full"
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                aspectRatio: aspectRatio, // Dynamic aspect ratio
                                overflow: "hidden",
                            }}
                        >
                            {/* Render iframe, with 'src' set initially, not reloaded each time */}
                            <iframe
                                src={website.url}
                                title={`Website Preview ${index}`}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "400%",
                                    height: "400%",
                                    border: "none",
                                    transform: `scale(${website.scale})`,
                                    transformOrigin: "top left",
                                }}
                                sandbox="allow-scripts allow-same-origin"
                            />
                        </div>
                    </div>
                ))}
            </div>

            <button
                onClick={handleNext}
                className="absolute right-2 bg-primary text-white rounded-full w-10 h-10 flex items-center justify-center z-10"
            >
                &#8594;
            </button>
        </div>
    );
};

export default WebsiteCarousel;