
import React, {useEffect, useRef, useState} from "react";
import {Button} from "react-daisyui";
import {easing, Slide} from "@mui/material";
import {ReportClick} from "../utils/gtag";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";

export default function HeroLandingSection2(props) {
    const {openPage} = useAppContext();
    const [visible, setVisble] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        let mc = document.querySelector("#maincontainer")
        if(mc!== null) mc.addEventListener("scroll", (e) => {
            setScrollPos(e.target.scrollTop);
        });
        return(() => {
            mc.removeEventListener("scroll", (e) => {
                setScrollPos(e.target.scrollTop);
            });
        })
    })

    useEffect(() => {
        if(!visible) {
            let target = document.querySelector("#herolandingsection2");
            if(target !== null) {
                if(scrollPos > (target.scrollHeight +400)) setVisble(true);
            }
        }
    }, [scrollPos]);

    return (
        <section className="container flex h-auto w-full py-8 lg:py-20" id="herolandingsection2">
                <div className="grid gap-10 lg:gap-40 xl:grid-cols-2 items-start justify-center">
                    <div
                        className="flex order-last xl:order-first items-start justify-center"
                    >
                        {/*<Slide direction="right" in={visible} mountOnEnter unmountOnExit>*/}
                        <img
                            alt="TMHeroSection2"
                            id="hero-image-hero-section-2"
                            className="w-full align-middle rounded-lg scale 80 hover:scale-105"
                            src={"https://cdn.midjourney.com/5e4146ae-189e-45cd-829a-61d86257a72a/0_0.png"}
                        />
                        {/*</Slide>*/}
                    </div>
                    <div className={""}>
                        <p className="text-lg leading-relaxed mt-2 mb-2">
                            Met onze uitgebreide ervaring en expertise in webontwikkeling en app-ontwikkeling,
                            kunnen wij u helpen om uw online aanwezigheid te versterken en uw bedrijf naar
                            nieuwe hoogten te brengen. Van het ontwerpen van een <Link to={"/website-op-maat"} className="link-info link text-primary font-semibold"
                                onClick={() => {
                                    ReportClick("aantrekkelijke_en_responsive_website");
                                }}> aantrekkelijke en responsieve website</Link> tot het ontwikkelen van <Link to={"/mobiele-app-laten-maken"} className="link-info link text-primary font-semibold"
                               onClick={() => {
                                   ReportClick("krachtige_mobiele_applicaties");
                               }}> krachtige mobiele applicaties</Link> die uw klanten
                            betrekken en boeien, wij zijn er om u te ondersteunen bij elke stap van het proces.
                        </p>
                        <p className="text-lg leading-relaxed mt-2 mb-2">
                            Bij Tech-Mania geloven we in een persoonlijke benadering en streven we ernaar om
                            nauw met u samen te werken om uw visie tot leven te brengen. Ons team  <Link to={"/appontwikkelaar"}
                            className="link-info link text-primary font-semibold"
                            onClick={() => {
                                ReportClick("ervaren_appontwikkelaars");
                            }}>ervaren appontwikkelaars</Link> van staat klaar om uw ideeën te vertalen naar hoogwaardige
                            digitale oplossingen die uw bedrijf onderscheiden van de concurrentie.
                        </p>
                        <p className="text-lg leading-relaxed mt-2 mb-2">
                            Of u nu een start-up bent die een sterke online aanwezigheid wil opbouwen of een
                            gevestigd bedrijf dat zijn digitale strategie wil verbeteren, wij zijn hier om u te
                            helpen slagen.  <Link to={"/contact"} className="link-info link text-primary font-semibold"
                                              onClick={() => {
                                                  ReportClick("neem_vandaag_nog_contact");
                                              }}>
                            Neem vandaag nog contact</Link> met ons op en ontdek hoe Tech-Mania uw digitale ambities kan realiseren.
                        </p>
                        <div className="mt-8 inline-flex">
                            <Link to={"/diensten"}>
                            <Button color="primary" onClick={() => {ReportClick("bekijk_ons_aanbod_landing");}}>Bekijk Ons Aanbod</Button>
                            </Link>
                        </div>
                    </div>
            </div>
        </section>
    );
};
