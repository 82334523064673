import React from "react";
import { Language } from "./language";
import { useLanguage } from "./languagecontext";

const LanguageSwitcher = () => {
    const { currentLanguage, setCurrentLanguage } = useLanguage();

    return (
        <div className="flex justify-center text-secondary text-sm">
            <select
                onChange={(e) => setCurrentLanguage(e.target.value)}
                className="pl-2 py-1 bg-gray-200 rounded-lg border border-secondary focus:outline-none focus:ring focus:ring-secondary-100 select-accent:text-primary font-medium uppercase"
                value={currentLanguage}
                style={{width:"fit-content"}}
            >
                <option value={Language.EN}>En</option>
                <option value={Language.FR}>Fr</option>
                <option value={Language.DU}>Nl</option>
            </select>
        </div>
    );
};

export default LanguageSwitcher;