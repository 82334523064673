import Tooltip from "@mui/material/Tooltip";
import React, {useEffect} from "react";
import HeroLanding1 from "../components/HeroLanding1";
import HeroLandingServices from "../components/HeroLandingServices";
import {HeroLandingSection1} from "../components/HeroLandingSection1";
import ImageBanner1 from "../components/ImageBanner1";
import HeroLandingSection2 from "../components/HeroLandingSection2";
import HeroReact from "../components/HeroReact";
import ImageBanner2 from "../components/ImageBanner2";
import OnzePartners from "../components/OnzePartners";
import LinkBanner from "../components/linkbanner";
import BreadCrumb from "../components/BreadCrumb";
import PromotionBanner from "../components/promotionbanner";
import WebsiteCarousel from "../components/websitecarousel";
import {Pricing} from "../components/Pricing";

export default function LandingPage(props) {

    return (
            <div className={"mt-16 flex flex-col w-full h-auto justify-items-center"}>

                {/*<BreadCrumb setOpenPage={setOpenPage} openPage={openPage}/>*/}

                {/*<LinkBanner/>*/}

                {/*<HeroLanding setOpenPage={setOpenPage} openPage={openPage}/>*/}

                <PromotionBanner/>

                <HeroLanding1 />

                <HeroLandingServices />

                <WebsiteCarousel/>

                {/*<HeroLandingSection1 />

                <ImageBanner1 />

                <HeroLandingSection2 />*/}

                <Pricing />

                <hr className="container my-1 border-primary"/>

                {/*<HeroReact />*/}

                <ImageBanner2 />

                {/*<hr className="my-6 border-primary"/>*/}

                <OnzePartners/>
            </div>
    );
}
