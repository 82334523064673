import { Badge, Button, Card, Indicator, Tabs } from "react-daisyui";
import { useState } from "react";
import {useLanguage} from "../utils/languagecontext";

export const Pricing = () => {
    const [pricingDuration, setPricingDuration] = useState("yearly");
    const { translations } = useLanguage();
    console.log("Rendered Translations in Component:", translations);

    return (
        <>
            <section className="py-8 lg:py-20" id="pricing">
                <div className="container">
                    <div className="text-center">
                        <h2 className="text-4xl font-semibold">{translations?.websitePrices?.title || "Translation is missing."}</h2>
                        <p className="mt-2 text-lg">
                            {translations?.websitePrices?.titledescription || "Translation is missing."}
                        </p>
                    </div>
                    {/*<div className="mt-6 flex justify-center">
                        <Tabs variant="boxed">
                            <Tabs.Tab
                                active={pricingDuration == "monthly"}
                                onClick={() => setPricingDuration("monthly")}
                                className="lg:px-10"
                            >
                                Monthly
                            </Tabs.Tab>
                            <Tabs.Tab
                                active={pricingDuration == "yearly"}
                                onClick={() => setPricingDuration("yearly")}
                                className={`lg:px-10 ${Indicator.className()} }`}
                            >
                                Yearly
                                <Indicator.Item>
                                    <Badge color={"neutral"}>-40%</Badge>
                                </Indicator.Item>
                            </Tabs.Tab>
                        </Tabs>
                    </div>*/}
                    <div className="mt-8 grid gap-8 lg:grid-cols-3">
                        <Card className="">
                            <Card.Body className="p-3 gap-0">
                                <Card className="bg-base-200 text-base-content">
                                    <Card.Body className="p-6 gap-0">
                                        <h3 className="text-xl font-semibold">{translations?.websitePrices?.standard.title || "Translation is missing."}</h3>
                                        <p className="mt-2 flex items-baseline">
                      <span className="text-4xl font-bold tracking-tight">
                        €500- €1000
                      </span>
                                            {/*<span className="ml-1 text-xl font-semibold">/month</span>*/}
                                        </p>
                                        <p className="mt-4 text-sm">
                                            {translations?.websitePrices?.standard.titledescription || "Translation is missing."}
                                        </p>
                                    </Card.Body>
                                </Card>

                                <div className="p-6">
                                    <ul
                                        className="list-inside list-disc space-y-3 text-base-content text-sm"
                                        role="list"
                                    >
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.standard.feature1 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.standard.feature2 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.standard.feature3 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.standard.feature4 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.standard.feature5 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.standard.feature6 || "Translation is missing."}</span>
                                        </li>
                                    </ul>
                                </div>
                                <Button
                                    variant="outline"
                                    color="primary"
                                    fullWidth
                                    className="mt-auto"
                                >
                                    {translations?.websitePrices?.standard.button || "Translation is missing."}
                                </Button>
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Body className="p-3  gap-0">
                                <Card className="bg-base-200  text-base-content">
                                    <Card.Body className="p-6 gap-0">
                                        <div className="flex justify-between">
                                            <h3 className="text-xl font-semibold">
                                                {translations?.websitePrices?.professional.title || "Translation is missing."}
                                            </h3>
                                            {/*<Badge variant="outline" className="font-medium">
                                                Best Offer
                                            </Badge>*/}
                                        </div>

                                        <p className="mt-4 flex items-baseline">
                      {/*<span className="text-5xl font-bold tracking-tight text-primary">
                        ${pricingDuration == "monthly" ? "99" : "59"}
                      </span>*/}
                      <span className="text-4xl font-bold tracking-tight">
                        €1500- €3000
                      </span>
                                            {/*<span className="ml-1 text-xl font-semibold">/month</span>*/}
                                        </p>
                                        <p className="mt-6 text-sm">
                                            {translations?.websitePrices?.professional.titledescription || "Translation is missing."}
                                        </p>
                                    </Card.Body>
                                </Card>
                                <div className="p-6">
                                    <ul
                                        className="list-inside list-disc space-y-3 text-base-content text-sm"
                                        role="list"
                                    >
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.professional.feature1 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="ms-3">{translations?.websitePrices?.professional.feature2 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.professional.feature3 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="ms-3">{translations?.websitePrices?.professional.feature4 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="ms-3">{translations?.websitePrices?.professional.feature5 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="ms-3">{translations?.websitePrices?.professional.feature6 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="ms-3">{translations?.websitePrices?.professional.feature7 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="ms-3">{translations?.websitePrices?.professional.feature8 || "Translation is missing."}</span>
                                        </li>
                                    </ul>
                                </div>
                                <Button color="primary" fullWidth className="mt-auto">
                                    {translations?.websitePrices?.professional.button || "Translation is missing."}
                                </Button>
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Body className="p-3  gap-0">
                                <Card className="bg-base-200">
                                    <Card.Body className="p-6  gap-0">
                                        <h3 className="text-xl font-semibold">{translations?.websitePrices?.premium.title || "Translation is missing."}</h3>
                                        <p className="mt-4 flex items-baseline">
                                            {/*<span className="text-5xl font-bold tracking-tight">
                        ${pricingDuration == "monthly" ? "199" : "119"}
                      </span>*/}
                      <span className="text-4xl font-bold tracking-tight">
                        €5000- €10000
                      </span>
                                            {/*<span className="ml-1 text-xl font-semibold">/month</span>*/}
                                        </p>
                                        <p className="mt-6 text-sm">
                                            {translations?.websitePrices?.premium.titledescription || "Translation is missing."}
                                        </p>
                                    </Card.Body>
                                </Card>

                                <div className="p-6">
                                    <ul
                                        className="list-inside list-disc space-y-3 text-sm"
                                        role="list"
                                    >
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature1 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature2 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature3 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature4 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature5 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature6 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature7 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature8 || "Translation is missing."}</span>
                                        </li>
                                        <li>
                                            <span className="ms-3">{translations?.websitePrices?.premium.feature9 || "Translation is missing."}</span>
                                        </li>
                                    </ul>
                                </div>
                                <Button
                                    variant="outline"
                                    color="primary"
                                    fullWidth
                                    className="mt-auto"
                                >
                                    {translations?.websitePrices?.premium.button || "Translation is missing."}
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </section>
        </>
    );
};
