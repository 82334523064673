
import React, {useState} from "react";
import DevicesIcon from "@mui/icons-material/Devices";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WebIcon from "@mui/icons-material/Web";
import {useAppContext} from "../context/appContext";
import {ScrollToSection} from "../utils/general";
import {ReportClick} from "../utils/gtag";
import {Link} from "react-router-dom";
import {useLanguage} from "../utils/languagecontext";

export default function HeroLandingServices(prevProps, nextProps) {
    const { openPage } = useAppContext();
    const [scrollPos, setScrollPos] = useState(0);
    const { translations } = useLanguage();
    console.log("Rendered Translations in Component:", translations);

    return (
        <section className="flex flex-col w-full h-auto bg-gradient-to-r from-cyan-500 to-blue-500">
            <div className="container -mt-1 py-8 lg:py-20">
                <div className="flex flex-wrap gap-6 lg:gap-0">


                    <div className="w-full lg:w-4/12 px-4 text-center hover:scale-105">
                        <Link to={"/diensten"}
                              onClick={(e) => {
                                  ScrollToSection("opmaatgemaaktewebsites");
                                  ReportClick("diensten_opmaatgemaaktewebsites");
                              }}>
                        <div
                            className="relative flex flex-col min-w-0 break-words bg-secondary-content/5 w-full h-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                            <div className="px-4 py-5 leading-relaxed">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                    <i className="fas fa-award"></i>
                                    <DevicesIcon sx={{fontSize: 34}}/>
                                </div>
                                <h6 className="text-xl font-bold text-secondary-content text-shadow-lg">{translations?.heroLandingServices?.title1 || "Translation is missing."}</h6>
                                <p className="mt-2 text-lg px-2 text-base-100 text-shadow-lg">
                                    {translations?.heroLandingServices?.description1 || "Translation is missing."}
                                </p>
                            </div>
                        </div>
                        </Link>
                    </div>


                    <div className="w-full lg:w-4/12 px-4 text-center hover:scale-105">
                        <Link to={"/diensten"}
                              onClick={(e) => {
                                  ScrollToSection("mobieleapplicatieontwikkeling");
                                  ReportClick("diensten_mobieleapplicatieontwikkeling");
                              }}>
                        <div
                            className="relative flex flex-col min-w-0 bg-secondary-content/5 w-full h-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                            <div className="px-4 py-5 leading-relaxed">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                    <i className="fas fa-award"></i>
                                    <PhoneIphoneIcon sx={{fontSize: 34}}/>
                                </div>
                                <h6 className="text-xl font-bold text-secondary-content text-shadow-lg">{translations?.heroLandingServices?.title2 || "Translation is missing."}</h6>
                                <p className="mt-2 text-lg px-2 text-base-100 text-shadow-lg">
                                    {translations?.heroLandingServices?.description2 || "Translation is missing."}
                                </p>
                            </div>
                        </div>
                        </Link>
                    </div>

                    <div className="w-full lg:w-4/12 px-4 text-center hover:scale-105">
                        <Link to={"/diensten"}
                              onClick={(e) => {
                                  ScrollToSection("webapplicatieontwikkeling");
                                  ReportClick("diensten_webapplicatieontwikkeling");
                              }}>
                        <div
                            className="relative flex flex-col min-w-0 break-words bg-secondary-content/5 w-full h-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                            <div className="px-4 py-5 leading-relaxed">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                    <i className="fas fa-award"></i>
                                    <WebIcon sx={{fontSize: 34}}/>
                                </div>
                                <h6 className="text-xl font-bold text-secondary-content text-shadow-lg">{translations?.heroLandingServices?.title3 || "Translation is missing."}</h6>
                                <p className="mt-2 text-lg px-2 text-base-100 text-shadow-lg">
                                    {translations?.heroLandingServices?.description3 || "Translation is missing."}
                                </p>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

            </div>
        </section>
    );
};
