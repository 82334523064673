import {HeroLandingSection1} from "../components/HeroLandingSection1";
import ImageBanner1 from "../components/ImageBanner1";
import HeroLandingSection2 from "../components/HeroLandingSection2";
import React from "react";

export default function EenTechnologischeToekomst() {
    return ( <div className="eenTechnologischeToekomst">
        <div className={"mt-16 flex flex-col w-full h-auto justify-items-center"}>
            <HeroLandingSection1 />

            <ImageBanner1 />

            <HeroLandingSection2 />
        </div>
    </div>)
}