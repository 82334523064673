import React from "react";
import {useLanguage} from "../utils/languagecontext";

export default function PromotionBanner(prevProps, nextProps) {
    const { translations } = useLanguage();
    console.log("Rendered Translations in Component:", translations);
    return (
        <div className="bg-orange-500 py-1.5 text-center text-xs text-neutral-content md:text-sm">
                <span className={"m-4"}>
                  {translations?.promoBanner?.part1 || "Translation is missing."}
                  <span className="mx-1 font-semibold text-warning bg-white/70 bg-opacity-90 rounded-badge px-2">{translations?.promoBanner?.code || "Translation is missing."}</span>
                    {translations?.promoBanner?.part2 || "Translation is missing."} 🚀
                </span>
            </div>
    )
}