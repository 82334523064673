import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import {getTranslations, Language} from "./language";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        return localStorage.getItem("appLanguage") || Language.DU; // Load language from storage
    });

    const setLanguage = (lang) => {
        setCurrentLanguage(lang);
        localStorage.setItem("appLanguage", lang);
    };

    useEffect(() => {
        console.log("Current Language Updated to:", currentLanguage);
    }, [currentLanguage]);

    let translations;
    translations = useMemo(() => getTranslations(currentLanguage), [currentLanguage]);

    return (
        <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage: setLanguage, translations }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);

    if (!context) {
        throw new Error("useLanguage must be used within a LanguageProvider");
    }

    return context;
};