export const Language = {
    EN: 'English',
    FR: 'French',
    DU: 'Dutch',
}

export const translations = {
    EN: {
        topBar: {
            home: "home",
            diensten: "services",
            nieuws: "news",
            info: "info",
            contact: "contact",
        },
        promoBanner: {
            part1: "Act early and use the code",
            code: "TECHMA24",
            part2: "The next five customers qualify for a free standard website. Contact us today!",
        },
        heroLanding: {
            title: "Digital Customization",
            intro: "Looking for a partner to create custom websites and mobile applications?\n" +
                "                                Our expert team is ready to bring your digital vision to life, whether you’re a start-up or an established business.\n" +
                "                                Contact us today and discover how we can help you grow and thrive in the digital world.",
            detailsButton: "Learn More",
            contactButton: "Contact Us",
        },
        heroLandingServices: {
            title1: "CUSTOM-BUILT WEBSITES",
            description1: "From simple informational websites to complex e-commerce platforms, we develop websites that seamlessly align with your brand identity and provide functionalities that engage your target audience.",
            title2: "MOBILE APPLICATION DEVELOPMENT",
            description2: "We create innovative mobile applications for both iOS and Android, allowing you to engage your customers, streamline your business processes, and explore new business opportunities.",
            title3: "WEB APPLICATION DEVELOPMENT",
            description3: "Whether it's automating internal processes or creating customer-focused tools, we develop custom web applications that enhance your business efficiency.",
        },
        websitePrices: {
            title: "Website Pricing Plans",
            titledescription: "Explore flexible pricing that scales with your needs. No hidden fees, just transparent options for your success.",
            standard: {
                title: "Standard",
                titledescription: "Small businesses, startups, or personal projects needing a basic online presence.",
                feature1: "Simple, responsive template with limited customization.",
                feature2: "Up to 5 static pages (e.g., Home, About, Services, Contact).",
                feature3: "One year of free hosting and a basic domain (.com, .net).",
                feature4: "Submission to search engines, meta tags, and a sitemap.",
                feature5: "Basic speed optimization.",
                feature6: "Email support for the first 6 months.",
                button: "Get Started",
            },
            professional: {
                title: "Professional",
                titledescription: "Established businesses looking for enhanced functionality and branding.",
                feature1: "Tailored UI/UX with brand integration.",
                feature2: "Up to 10 pages, including blog or portfolio sections.",
                feature3: "Basic online store (up to 10 products), payment gateway integration.",
                feature4: "One year of premium hosting with SSL certificate.",
                feature5: "Advanced on-page SEO, Google Analytics setup.",
                feature6: "Faster loading with caching and CDN integration.",
                feature7: "Priority email and chat support for one year.",
                feature8: "Basic third-party tools (e.g., Mailchimp, booking plugins).",
                button: "Get Professional",
            },
            premium: {
                title: "Premium",
                titledescription: "High-growth businesses or enterprises requiring comprehensive solutions.",
                feature1: "Fully custom website with animations, video backgrounds, and advanced visuals.",
                feature2: "Scalable to any size, including a custom CMS.",
                feature3: "Full-scale eCommerce capabilities, unlimited products, and advanced inventory management.",
                feature4: "One year of enterprise-grade hosting, SSL, and premium domain support.",
                feature5: "Advanced SEO strategy, integration with marketing tools (CRM, email marketing), and content optimization.",
                feature6: "Ultra-fast speeds with advanced CDN, compression, and server-side optimizations.",
                feature7: "24/7 dedicated support and maintenance for the first year.",
                feature8: "API integrations, chatbots, advanced analytics dashboards.",
                feature9: "Multilingual support, accessibility compliance, and backups.",
                button: "Get Premium",
            },
        },
    },
    FR: {
        topBar: {
            home: "home",
            diensten: "services",
            nieuws: "actualités",
            info: "info",
            contact: "contact",
        },
        promoBanner: {
            part1: "Agissez tôt et utilisez le code",
            code: "TECHMA24",
            part2: "Les cinq prochains clients auront droit à un site web standard gratuit. Contactez-nous dès aujourd'hui !",
        },
        heroLanding: {
            title: "Solutions numériques sur mesure",
            intro: "Vous cherchez un partenaire pour créer des sites web sur mesure et des applications mobiles ?\n" +
                "Notre équipe d'experts est prête à donner vie à votre vision numérique, que vous soyez une start-up ou une entreprise bien établie.",
            detailsButton: "En savoir plus",
            contactButton: "Contactez-nous",
        },
        heroLandingServices: {
            title1: "SITES WEB SUR MESURE",
            description1: "Des sites informatifs simples aux plateformes e-commerce complexes, nous concevons des sites web qui s'harmonisent parfaitement avec votre identité de marque et offrent des fonctionnalités qui captivent votre audience cible.",
            title2: "DÉVELOPPEMENT D'APPLICATIONS MOBILES",
            description2: "Nous créons des applications mobiles innovantes pour iOS et Android, vous permettant d'engager vos clients, d'optimiser vos processus commerciaux et d'explorer de nouvelles opportunités d'affaires.",
            title3: "DÉVELOPPEMENT D'APPLICATIONS WEB",
            description3: "Qu'il s'agisse d'automatiser des processus internes ou de créer des outils orientés client, nous développons des applications web sur mesure qui rendent votre entreprise plus efficace.",
        },
        websitePrices: {
            title: "Plans Tarifs pour Sites Web",
            titledescription: "Découvrez des tarifs flexibles qui évoluent selon vos besoins. Pas de frais cachés, seulement des options transparentes pour votre réussite.",
            standard: {
                title: "Standard",
                titledescription: "Petites entreprises, startups ou projets personnels nécessitant une présence en ligne de base.",
                feature1: "Modèle simple et réactif avec personnalisation limitée.",
                feature2: "Jusqu'à 5 pages statiques (par ex., Accueil, À propos, Services, Contact).",
                feature3: "Un an d'hébergement gratuit et un domaine basique (.com, .net).",
                feature4: "Soumission aux moteurs de recherche, balises méta et plan du site.",
                feature5: "Optimisation basique de la vitesse.",
                feature6: "Assistance par e-mail pendant les 6 premiers mois.",
                button: "Commencer",
            },
            professional: {
                title: "Professionnel",
                titledescription: "Entreprises établies recherchant des fonctionnalités améliorées et un meilleur branding.",
                feature1: "UI/UX personnalisée avec intégration de marque.",
                feature2: "Jusqu'à 10 pages, y compris des sections blog ou portfolio.",
                feature3: "Boutique en ligne de base (jusqu'à 10 produits), intégration de passerelle de paiement.",
                feature4: "Un an d'hébergement premium avec certificat SSL.",
                feature5: "SEO avancé sur la page, configuration de Google Analytics.",
                feature6: "Chargement plus rapide grâce à la mise en cache et à l'intégration CDN.",
                feature7: "Support prioritaire par e-mail et chat pendant un an.",
                feature8: "Outils tiers de base (par ex., Mailchimp, plugins de réservation).",
                button: "Choisir Professionnel",
            },
            premium: {
                title: "Premium",
                titledescription: "Entreprises à forte croissance ou grandes entreprises nécessitant des solutions complètes.",
                feature1: "Site web entièrement personnalisé avec animations, vidéos en arrière-plan et visuels avancés.",
                feature2: "Évolutif à toute taille, incluant un CMS sur mesure.",
                feature3: "Capacités e-commerce complètes, produits illimités et gestion avancée des stocks.",
                feature4: "Un an d'hébergement de niveau entreprise, SSL et support pour domaine premium.",
                feature5: "Stratégie SEO avancée, intégration avec des outils marketing (CRM, email marketing) et optimisation du contenu.",
                feature6: "Vitesse ultra-rapide grâce à un CDN avancé, compression et optimisations côté serveur.",
                feature7: "Support et maintenance dédiés 24/7 pour la première année.",
                feature8: "Intégrations API, chatbots, tableaux de bord d'analyse avancés.",
                feature9: "Support multilingue, conformité à l'accessibilité et sauvegardes.",
                button: "Choisir Premium",
            },
        },
    },
    DU: {
        topBar: {
            home: "home",
            diensten: "diensten",
            nieuws: "nieuws",
            info: "info",
            contact: "contact",
        },
        promoBanner: {
            part1: "Wees er vroeg bij en gebruik de code",
            code: "TECHMA24",
            part2: "De eerstvolgende vijf klanten komen in aanmerking voor een gratis standaard website. Neem vandaag nog contact met ons op!",
        },
        heroLanding: {
            title: "Digitaal Maatwerk",
            intro: "Op zoek naar een partner voor op maat gemaakte websites en mobiele applicaties?\n" +
                "                                Ons deskundig team staat klaar om uw digitale visie tot leven te brengen, of u\n" +
                "                                nu\n" +
                "                                een start-up bent of een gevestigd bedrijf.\n" +
                "                                Neem vandaag nog contact met ons op en ontdek hoe wij u kunnen helpen om te\n" +
                "                                groeien\n" +
                "                                en te gedijen in de digitale wereld.",
            detailsButton: "Meer weten",
            contactButton: "Contacteer ons",
        },
        heroLandingServices: {
            title1: "OP MAAT GEMAAKTE WEBSITES",
            description1: "Van eenvoudige informatieve websites tot complexe e-commerceplatforms, wij ontwikkelen websites die naadloos aansluiten bij uw merkidentiteit en functionaliteiten bieden die uw doelgroep aanspreken.",
            title2: "MOBIELE APPLICATIE ONTWIKKELING",
            description2: "Wij creëren innovatieve mobiele applicaties voor zowel iOS als Android, waarmee\n" +
                "                                    u uw klanten kunt betrekken, uw bedrijfsprocessen kunt stroomlijnen en nieuwe\n" +
                "                                    zakelijke kansen kunt ontdekken.",
            title3: "WEBAPPLICATIE ONTWIKKELING",
            description3: "Of het nu gaat om het automatiseren van interne processen of het creëren van\n" +
                "                                    klantgerichte tools, wij ontwikkelen op maat gemaakte webapplicaties die uw\n" +
                "                                    bedrijf efficiënter maken.",
        },
        websitePrices: {
            title: "Website Prijsplannen",
            titledescription: "Ontdek flexibele prijzen die meegroeien met jouw behoeften. Geen verborgen kosten, alleen transparante opties voor jouw succes.",
            standard: {
                title: "Standaard",
                titledescription: "Kleine bedrijven, startups of persoonlijke projecten die een basis online aanwezigheid nodig hebben.",
                feature1: "Eenvoudige, responsieve template met beperkte aanpassingsmogelijkheden.",
                feature2: "Tot 5 statische pagina's (bijv. Home, Over, Diensten, Contact).",
                feature3: "Eén jaar gratis hosting en een basisdomein (.com, .net).",
                feature4: "Indiening bij zoekmachines, meta-tags en een sitemap.",
                feature5: "Basis snelheidsoptimalisatie.",
                feature6: "E-mailondersteuning voor de eerste 6 maanden.",
                button: "Aan de slag",
            },
            professional: {
                title: "Professioneel",
                titledescription: "Gevestigde bedrijven die op zoek zijn naar verbeterde functionaliteit en branding.",
                feature1: "Op maat gemaakte UI/UX met merkintegratie.",
                feature2: "Tot 10 pagina's, inclusief blog- of portfoliosecties.",
                feature3: "Basis online winkel (tot 10 producten), integratie van betalingsgateway.",
                feature4: "Eén jaar premium hosting met SSL-certificaat.",
                feature5: "Geavanceerde on-page SEO, Google Analytics setup.",
                feature6: "Snellere laadtijden met caching en CDN-integratie.",
                feature7: "Prioritaire e-mail- en chatondersteuning voor één jaar.",
                feature8: "Basisintegratie met derden (bijv. Mailchimp, boekingsplugins).",
                button: "Kies Professioneel",
            },
            premium: {
                title: "Premium",
                titledescription: "Snelgroeiende bedrijven of ondernemingen die uitgebreide oplossingen nodig hebben.",
                feature1: "Volledig aangepaste website met animaties, video-achtergronden en geavanceerde visuals.",
                feature2: "Schaalbaar naar elke omvang, inclusief een op maat gemaakt CMS.",
                feature3: "Volledige e-commerce mogelijkheden, onbeperkte producten en geavanceerd voorraadbeheer.",
                feature4: "Eén jaar hosting op ondernemingsniveau, SSL en ondersteuning voor premium domeinen.",
                feature5: "Geavanceerde SEO-strategie, integratie met marketingtools (CRM, e-mailmarketing) en contentoptimalisatie.",
                feature6: "Ultrasnelle snelheden met geavanceerde CDN, compressie en server-side optimalisaties.",
                feature7: "24/7 toegewijde ondersteuning en onderhoud voor het eerste jaar.",
                feature8: "API-integraties, chatbots, geavanceerde analysemogelijkheden.",
                feature9: "Meertalige ondersteuning, toegankelijkheidsnormen en back-ups.",
                button: "Kies Premium",
            },
        },
    },
};

export const getTranslations = (languageCode) => {
    switch (languageCode) {
        case Language.EN:
            return translations.EN;
        case Language.FR:
            return translations.FR;
        case Language.DU:
            return translations.DU;
        default:
            console.warn(`Invalid language code: ${languageCode}. Falling back to English.`);
            return translations.EN; // Default to English if invalid code
    }
};